import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Open WOD 21.3`}</strong></p>
    <p>{`For total time:`}</p>
    <p>{`15 front squats\\
30 toes-to-bars\\
15 thrusters\\
Then, rest 1 minute before continuing with:\\
15 front squats\\
30 chest-to-bar pull-ups\\
15 thrusters\\
Then, rest 1 minute before continuing with:\\
15 front squats \\
30 bar muscle-ups\\
15 thrusters `}</p>
    <p>{`Workout 21.4 begins immediately upon completing or reaching the time cap
for 21.3.`}</p>
    <p><em parentName="p">{`95 lb. for the front squats and thrusters`}</em></p>
    <p>{`Time cap: 15 min.`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`Open WOD 21.4`}</strong></p>
    <p>{`Complete the following complex for max load:`}</p>
    <p>{`1 deadlift\\
1 clean\\
1 hang clean\\
1 jerk`}</p>
    <p>{`Time begins immediately following the completion of 21.3.`}</p>
    <p>{`Time cap: 7 min.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      